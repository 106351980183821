import React, { useState } from "react";
import { getReceivedCards } from "../../util/Http";
import { useQuery } from "@tanstack/react-query";
import LoadingOne from "../../Components/Ui/LoadingOne";
import { useTranslation } from "react-i18next";
import styles from "./ReceivedCards.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js";
import OrderPdfContent from "../../Components/OrderPdfContent/OrderPdfContent";
import Container from "react-bootstrap/esm/Container";
import NoDataPage from "../../Components/Ui/NoDataPage";
import ScrollTopButton from "../../Components/Ui/ScrollTopButton";
import SearchField from "../../Components/Ui/SearchField";
import MainButton from "../../Components/Ui/MainButton";

const ReceivedCardsView = () => {
  const { t: key } = useTranslation();
  const [searchInput, setSearchInput] = useState("");

  const token = JSON.parse(localStorage.getItem("token"));
  let isArLang = localStorage.getItem("i18nextLng") === "ar";

  const { data, isLoading } = useQuery({
    queryKey: ["receivedCards", token],
    queryFn: () => getReceivedCards({ token }),
    enabled: !!token,
    staleTime: 300000,
  });

  const handleSearch = (e, searchTerm) => {
    e.preventDefault();
    setSearchInput(searchTerm);
  };

  const filteredCards =
    data?.data?.filter((card) => {
      const searchValue = searchInput.trim().toLowerCase();
      return (
        card?.sender?.name?.toLowerCase().includes(searchValue) || // Search by sender
        card?.recipient?.name?.toLowerCase().includes(searchValue) || // Search by recipient
        card?._id.includes(searchValue)
      );
    }) || [];

  const generatePDF = (cardId) => {
    const element = document.getElementById(`${cardId}`);
    html2pdf(element);
  };

  return (
    <>
      <ScrollTopButton />
      <Row className={styles.orders_body}>
        {isLoading ? (
          <LoadingOne />
        ) : data?.data?.length > 0 ? (
          <>
         
            <div className="d-flex flex-wrap justify-content-between align-items-center my-4">
            <h4 className="text-secondary mt-2 mb-0">{key("receivedCards")}</h4>
              <SearchField className="my-2" onSearch={handleSearch} text={key("search")} />
            </div>

            {filteredCards.map((card, index) => (
              <Col className={styles.order_col} key={`${card._id}_${index}`} md={6} lg={4}>
                <div className="d-none">
                  <Container id={`${card._id}`} className="d-flex justify-content-center align-items-center flex-column my-4">
                    <OrderPdfContent order={card} />
                  </Container>
                </div>

                <div className={`${styles.order_item} ${isArLang && styles.order_item_ar}`}>
                  <div>
                    <h4 className={styles.main_title}>{key("cardDetails")}</h4>
                    <ul>
                      <li>
                        <span>{key("sender")}</span>
                        <span>{card?.sender?.name || key("unknownSender")}</span>
                      </li>
                      <li>
                        <span>{key("recipient")}</span>
                        <span>{card?.recipient?.name || key("unknownRecipient")}</span>
                      </li>
                      <li>
                        <span>{key("store")}</span>
                        <span>{card?.shop?.name || key("unknownStore")}</span>
                      </li>
                      <li>
                        <span>{key("message")}</span>
                        <span>{card?.text?.message || key("noMessage")}</span>
                      </li>
                      <li>
                        <span>{key("date")}</span>
                        <span>{new Date(card?.createdAt).toLocaleDateString()}</span>
                      </li>
                      <li>
                        <span>{key("value")}</span>
                        <span>{card?.totalPricePaid} {key("sar")}</span>
                      </li>
                      <li>
                        <span>{key("discountUsed")}</span>
                        <span>{card?.discountCode?.isUsed ? key("yes") : key("no")}</span>
                      </li>
                    </ul>
                    <hr />
                  </div>
       
                  <div className="d-flex justify-content-center align-items-center mt-3 flex-wrap">
                      <div className="my-2">
                        <MainButton
                          onClick={() => window.location.href = `https://giveagift.com.sa/gift-card/${card._id}`}
                          type="white"
                          text={key("viewCard")}
                          
                        />
                      </div>
                    </div>
                  </div>
              </Col>
            ))}
          </>
        ) : (
          <NoDataPage text={key("noReceivedCards")} />
        )}
      </Row>
    </>
  );
};

export default ReceivedCardsView;
