import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { object, ref, string } from "yup";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYinYang } from "@fortawesome/free-solid-svg-icons";
import InputErrorMessage from "./InputErrorMessage";
import styles from "./UserUpdateModal.module.css";
import { CountriesPhoneNumbers } from "../Logic/Logic";
import { useMutation } from "@tanstack/react-query";
import { controlUsers } from "../../util/Http";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const getPhoneValidationSchema = (country, key) => {
  const phoneRegex = {
    EG: /^(\+20)?1[0125][0-9]{8}$/,
    SA: /^(\+966)?5[0-9]{8}$/,
    UAE: /^(\+971)?5[0-9]{8}$/,
    KW: /^(\+965)?[0-9]{8}$/,
    US: /^(\+1)?[0-9]{10}$/,
  };

  return object({
    name: string().min(3, key("nameValidation1")).max(20, key("nameValidation2")).required(key("nameValidation3")),
    email: string().email(key("emailValidation1")).required(key("emailValidation2")),
    phone: string().matches(phoneRegex[country], key("invalidPhoneNumber")).required(key("phoneNumberRequired")),
    merchantShop: string().required(key("merchantRec")),
  });
};

const UserUpdateModal = ({ show, handleClose, userData, shops, token, refetch }) => {
  const { t: key } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [physicalShopsOptions, setPhysicalShopsOptions] = useState([]);
  let isArLang = localStorage.getItem("i18nextLng") === "ar";
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    if (shops) {
      console.log(shops)
      const options = shops
        .filter((shop) => shop?.isOnline === false) // Filter physical shops
        .map((shop) => ({ label: shop.name, value: shop._id })); // Map to desired format

      setPhysicalShopsOptions(options);
    }
  }, [shops]);


  const { mutate, isPending } = useMutation({
    mutationFn: controlUsers,
  });

  const initialValues = {
    name: userData.name || "",
    email: userData.email || "",
    phone: userData.phone || "",
    merchantShop: userData.merchantShop || "",
    password:"",
    passwordConfirm:""
  };

  const onSubmit = (values) => {
    
    let phoneBeginning = "966";
    switch (selectedCountry) {
      case "SA":
        phoneBeginning = "966";
        break;
      case "EG":
        phoneBeginning = "20";
        break;
      case "UAE":
        phoneBeginning = "971";
        break;
      case "KW":
        phoneBeginning = "965";
        break;
      case "US":
        phoneBeginning = "1";
        break;

      default:
        break;
    }
    const updatedValues = { ...values, phone: `${phoneBeginning}${values.phone}` };
    mutate(
      { formData: updatedValues, token, type: "update", userId:userData._id }, // Ensure "update" type is used
      {
        onSuccess: () => {
          notifySuccess(key("opSuccess"));
          refetch();
          handleClose();
        },
        onError: () => {
          notifyError(key("wrong"));
        },
      }
    );
  };
  

  const validationSchema = getPhoneValidationSchema(selectedCountry, key);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{key("update")} {key("merchant")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ setFieldValue }) => (
            <Form className={styles.update_form}>
              {/* Name Field */}
              <Field
                type="text"
                name="name"
                placeholder={key("name")}
                className={styles.input}
              />
              <ErrorMessage name="name" component={InputErrorMessage} />

              {/* Email Field */}
              <Field
                type="email"
                name="email"
                placeholder={key("email")}
                className={styles.input}
              />
              <ErrorMessage name="email" component={InputErrorMessage} />

              {/* Merchant Shop Select */}
              <Select
                name="merchantShop"
                options={physicalShopsOptions}
                onChange={(value) => setFieldValue("merchantShop", value.value)}
              />
              <ErrorMessage name="merchantShop" component={InputErrorMessage} />

              {/* Country Select and Phone Number Field */}
              <div className={styles.field}>
                <div
                  className={`${styles.phone_num} d-flex align-items-center ${isArLang ? styles.ar_phoneNum : ""
                    }`}
                >
                  <Select
                    className={`${styles.select_input} me-2`} // Adds spacing to the right of the select
                    classNamePrefix="Country"
                    isClearable={false}
                    isSearchable={false}
                    placeholder={key("whatsAppNum2")}
                    name="Country"
                    options={CountriesPhoneNumbers}
                    defaultValue={CountriesPhoneNumbers[1]}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(value) => {
                      setSelectedCountry(value.value);
                      setFieldValue("Country", value.value);
                    }}
                  />
                  <Field
                    type="text"
                    id="phoneNum_Merchant"
                    name="phone"
                    className={`${styles.phone_input} flex-grow-1`} // Expands the input to fill available space
                  />
                </div>
                <ErrorMessage name="phone" component={InputErrorMessage} />
              </div>

              {/* Password Field */}
              <Field
                type="password"
                name="password"
                placeholder={key("password")}
                className={styles.input}
              />
              <ErrorMessage name="password" component={InputErrorMessage} />

              {/* Confirm Password Field */}
              <Field
                type="password"
                name="passwordConfirm"
                placeholder={key("confirmPass")}
                className={styles.input}
              />
              <ErrorMessage name="passwordConfirm" component={InputErrorMessage} />

              {/* Buttons */}
              <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleClose}>
                  {key("cancel")}
                </Button>
                <Button type="submit" className="ms-2">
                  {isPending ? <FontAwesomeIcon className="fa-spin" icon={faYinYang} /> : key("update")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );

};

export default UserUpdateModal;
